import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../components/layout"
import { LocalizedLink } from "../components/localized_link"
import SEO from "../components/seo"
import ShowcaseSection from "../components/showcase_section"
import { scrollWindowTo } from "../utils"
import isMobile from "../utils/is_mobile"

const IndexPage = props => {
  const { data, path, location } = props

  const home = { ...data.prismicHome.data }
  const allCustomMenu = data.allPrismicCustomMenu.edges

  return (
    <Layout path={path} location={location}>
      <SEO title="Home" />
      <div className="container flex head">
        <div className="content">
          <div className="vert">
            <h1>{home.head_title}</h1>
            <div className="description">
              <div
                dangerouslySetInnerHTML={{
                  __html: home.head_description.html,
                }}
              />
              <LocalizedLink to="/menu" className="arrow-link">
                <span>{home.head_link_text}</span>
                <svg
                  width="85"
                  height="16"
                  viewBox="0 0 85 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M74.4574 0L72.5992 1.41L79.9527 7H0V9H79.9527L72.5992 14.59L74.4574 16L85 8L74.4574 0Z"
                    fill="#F1F1F1"
                  />
                </svg>
              </LocalizedLink>
            </div>
          </div>
        </div>
        <div className="image">
          <Img fluid={home.head_image.localFile.childImageSharp.fluid} />
        </div>
        <div className="bottom">
          <div className="flex">
            <div className="introducer">
              <div className="flex">
                <div className="divider"></div>
                <div className="content">
                  <p className="lead">{home.scroll_call_title}</p>
                  <a
                    className="link"
                    onClick={() => scrollWindowTo("#home-page", 88)}
                    children={home.scroll_call_caption}
                  />
                  <div style={{ marginTop: 16 }}>
                    {home.meat_icons.map((item, index) => (
                      <Img
                        fluid={item.meat_image.localFile.childImageSharp.fluid}
                        key={index}
                        className="icon"
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="sections">
              <div className="flex">
                <div className="where">
                  <p className="lead">{home.head_section_one_title}</p>
                  <a
                    className="link arrow-link sober"
                    onClick={() =>
                      scrollWindowTo("#footer-where", isMobile() ? 96 : 0)
                    }
                  >
                    <span>{home.head_section_one_caption}</span>
                    <svg
                      width="85"
                      height="16"
                      viewBox="0 0 85 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M74.4574 0L72.5992 1.41L79.9527 7H0V9H79.9527L72.5992 14.59L74.4574 16L85 8L74.4574 0Z"
                        fill="#F1F1F1"
                      />
                    </svg>
                  </a>
                  <Img
                    fluid={
                      home.head_section_one_image.localFile.childImageSharp
                        .fluid
                    }
                  />
                </div>
                <div className="contact-us">
                  {/* <p className="lead">{home.head_section_two_title}</p> */}
                  <p className="lead">
                    <a
                      href={"tel:" + home.head_section_two_title}
                      target="_blank"
                    >
                      {home.head_section_two_title}
                    </a>
                  </p>
                  <a
                    className="link arrow-link sober"
                    onClick={() => scrollWindowTo("#contact-where")}
                  >
                    <span>{home.head_section_two_caption}</span>
                    <svg
                      width="85"
                      height="16"
                      viewBox="0 0 85 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M74.4574 0L72.5992 1.41L79.9527 7H0V9H79.9527L72.5992 14.59L74.4574 16L85 8L74.4574 0Z"
                        fill="#F1F1F1"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {allCustomMenu.map((menu, index) => {
        const customMenu = menu.node.data

        return customMenu.enabled ? (
          <div className="container section asporto" key={index}>
            <div className="home_asporto">
              <p className="title">{customMenu.home_title}</p>
              <p className="desc">{customMenu.home_description}</p>
              <LocalizedLink
                to={customMenu.slug}
                style={{ textDecoration: "none" }}
              >
                <div className="home_asporto_cta">
                  <span className="span">{customMenu.cta_description}</span>
                  <svg
                    width="85"
                    height="16"
                    viewBox="0 0 85 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M74.4574 0L72.5992 1.41L79.9527 7H0V9H79.9527L72.5992 14.59L74.4574 16L85 8L74.4574 0Z"
                      fill="#fff"
                    />
                  </svg>
                </div>
              </LocalizedLink>
            </div>
            {customMenu.home_image.localFile && (
              <div className="home_asporto_img1">
                <Img
                  fluid={customMenu.home_image.localFile.childImageSharp.fluid}
                />
              </div>
            )}
            {customMenu.home_image_two.localFile && (
              <div className="home_asporto_img2">
                <Img
                  fluid={
                    customMenu.home_image_two.localFile.childImageSharp.fluid
                  }
                />
              </div>
            )}
          </div>
        ) : null
      })}

      {/* {customMenu.enabled && (
        <div className="container section asporto">
          <div className="home_asporto">
            <p className="title">{customMenu.home_title}</p>
            <p className="desc">{customMenu.home_description}</p>
            <Link to={customMenu.slug} style={{ textDecoration: "none" }}>
              <div className="home_asporto_cta">
                <span className="span">{customMenu.cta_description}</span>
                <svg
                  width="85"
                  height="16"
                  viewBox="0 0 85 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M74.4574 0L72.5992 1.41L79.9527 7H0V9H79.9527L72.5992 14.59L74.4574 16L85 8L74.4574 0Z"
                    fill="#fff"
                  />
                </svg>
              </div>
            </Link>
          </div>
          <div className="home_asporto_img1">
            <Img
              fluid={customMenu.home_image.localFile.childImageSharp.fluid}
            />
          </div>
          <div className="home_asporto_img2">
            <Img
              fluid={customMenu.home_image_two.localFile.childImageSharp.fluid}
            />
          </div>
        </div>
      )} */}
      <div className="container section" id="home-page">
        <h1>{home.page_title}</h1>
        <div dangerouslySetInnerHTML={{ __html: home.page_description.html }} />
      </div>
      <div className="accent section absolutely-margin-on-top">
        <div className="image-section">
          <div className="image-container">
            <Img fluid={home.detail_image.localFile.childImageSharp.fluid} />
          </div>
        </div>
        <div className="container">
          <h1 className="custom">{home.detail_title}</h1>
          <p className="bigger white">{home.detail_description}</p>
          <p className="white">{home.detail_caption}</p>
        </div>
      </div>
      <div className="container">
        <ShowcaseSection
          title={home.section_one_caption}
          image={home.section_one_image.localFile.childImageSharp.fluid}
          inverted
        />
        <ShowcaseSection
          title={home.section_two_caption}
          image={home.section_two_image.localFile.childImageSharp.fluid}
        />
        <div className="custom-section flex">
          <div className="content">
            <h1>{home.section_three_title}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: home.section_three_description.html,
              }}
            />
          </div>
          <div className="images">
            <div className="img-section one">
              <div className="img-container">
                <Img
                  fluid={
                    home.section_three_image_one.localFile.childImageSharp.fluid
                  }
                />
              </div>
            </div>
            <div className="img-section two">
              <div className="img-container">
                <Img
                  fluid={
                    home.section_three_image_two.localFile.childImageSharp.fluid
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div style={{width: '100%', height: '200px', margin: '0 auto', background: 'yellow'}}></div> */}
    </Layout>
  )
}
export default IndexPage

export const query = graphql`
  query HomeQuery($localeRegex: String) {
    prismicHome(lang: { regex: $localeRegex }) {
      id
      lang
      data {
        head_title
        head_description {
          html
        }
        head_link_text
        scroll_call_title
        scroll_call_caption
        head_section_one_title
        head_section_one_caption
        head_section_two_title
        head_section_two_caption
        page_title
        page_description {
          html
        }
        detail_title
        detail_description
        detail_caption
        section_one_caption
        section_two_caption
        section_three_title
        section_three_description {
          html
        }
        head_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        head_section_one_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        detail_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        section_one_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        section_two_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        section_three_image_one {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        section_three_image_two {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        meat_icons {
          meat_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 52, quality: 95, pngCompressionSpeed: 1) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allPrismicCustomMenu(filter: { lang: { regex: $localeRegex } }) {
      edges {
        node {
          data {
            enabled
            home_title
            home_description
            cta_description
            slug
            home_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            home_image_two {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
