import React from "react"
import Img from "gatsby-image"

const ShowcaseSection = props => {

  const { image, title, inverted } = props

  return (
    <div className={`showcase-section ${ inverted ? 'inverted' : '' }`}>
      <div className="image-section">
        <div className="image-container">
          <Img fluid={image} />
        </div>
      </div>
      <span className="caption">
        {title}
      </span>
    </div>
  )
}

export default ShowcaseSection